// scss-docs-start mixin-color-scheme
@mixin color-scheme-alt($scheme: light) {
  @if $scheme == dark {
    @media (prefers-color-scheme: dark) {
      @content;
    }
  } @else if $scheme == light {
    @media (prefers-color-scheme: light) {
      @content;
    }
  } @else {
    // assume what gets passed here is not light or dark, thus is a CSS element,
    // e.g. "html.dark", or "[data-theme-dark]"
    #{$scheme} {
      @content;
    }
  }
}
// scss-docs-end mixin-color-scheme

@mixin color-scheme-property($scheme: light, $dual: false) {
  @if $dual {
    @if $scheme == dark {
      color-scheme: dark light;
    } @else if $scheme == light {
      color-scheme: light dark;
    }
  } @else {
    @if $scheme == dark {
      color-scheme: dark;
    } @else if $scheme == light {
      color-scheme: light;
    }
  }
}

@function alternative-color-scheme($scheme: light) {
  @if $scheme == dark {
    @return light;
  } @else if $scheme == light {
    @return dark;
  } @else {
    @return $scheme;
  }
}

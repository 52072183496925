/*!
 * Bootstrap v5.x.x (https://getbootstrap.com/)
 * Copyright 2011-2022 The Bootstrap Authors
 * Copyright 2011-2022 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 *
 * Bootstrap-Dark-plugin (https://vinorodrigues.github.io/bootstrap-dark-5/)
 * Copyright 2020-2022 Vino Rodrigues
 * This version is a dual color theme, but as a plugin
 * Include both bootstrap.css and this file and darkmode is optioned in by a
 * `prefers-color-scheme: dark` media query
 */
:root {
  color-scheme: light dark;
}

/*
 * ---------- Dark Mode ------------------------------------------------------
 */
@media (prefers-color-scheme: dark) {
  :root {
    --bs-blue: #375a7f;
    --bs-indigo: #673ab7;
    --bs-purple: #654ea3;
    --bs-pink: #e83e8c;
    --bs-red: #e74c3c;
    --bs-orange: #fd7e14;
    --bs-yellow: #f39c12;
    --bs-green: #00bc8c;
    --bs-teal: #45b5aa;
    --bs-cyan: #17a2b8;
    --bs-white: #fafafa;
    --bs-black: #111;
    --bs-gray: #7e7e7e;
    --bs-gray-dark: #121212;
    --bs-gray-100: #e1e1e1;
    --bs-gray-200: #cfcfcf;
    --bs-gray-300: #b1b1b1;
    --bs-gray-400: #9e9e9e;
    --bs-gray-500: #7e7e7e;
    --bs-gray-600: #626262;
    --bs-gray-700: #515151;
    --bs-gray-800: #3b3b3b;
    --bs-gray-900: #222;
    --bs-primary: #375a7f;
    --bs-secondary: #626262;
    --bs-success: #00bc8c;
    --bs-info: #17a2b8;
    --bs-warning: #f39c12;
    --bs-danger: #e74c3c;
    --bs-light: #9e9e9e;
    --bs-dark: #3b3b3b;
    --bs-primary-rgb: 55, 90, 127;
    --bs-secondary-rgb: 98, 98, 98;
    --bs-success-rgb: 0, 188, 140;
    --bs-info-rgb: 23, 162, 184;
    --bs-warning-rgb: 243, 156, 18;
    --bs-danger-rgb: 231, 76, 60;
    --bs-light-rgb: 158, 158, 158;
    --bs-dark-rgb: 59, 59, 59;
    --bs-white-rgb: 250, 250, 250;
    --bs-black-rgb: 17, 17, 17;
    --bs-body-color-rgb: 225, 225, 225;
    --bs-body-bg-rgb: 34, 34, 34;
    --bs-body-color: #e1e1e1;
    --bs-body-bg: #222;
    --bs-gradient: linear-gradient(180deg, rgba(17, 17, 17, 0.15), rgba(17, 17, 17, 0));
  }

  hr {
    color: #fafafa;
    background-color: currentColor;
    opacity: 0.1;
  }

  mark {
    background-color: rgba(243, 156, 18, 0.5);
  }

  a {
    color: #5f7b99;
  }
  a:hover {
    color: #4b6b8c;
  }

  a:not([href]):not([class]), a:not([href]):not([class]):hover {
    color: inherit;
  }

  pre {
    color: #e1e1e1;
  }
  pre code {
    color: inherit;
  }

  code {
    color: #45b5aa;
  }
  a > code {
    color: inherit;
  }

  kbd {
    color: #e1e1e1;
    background-color: #3b3b3b;
  }

  caption {
    color: #9e9e9e;
  }

  .blockquote-footer {
    color: #626262;
  }

  .img-thumbnail {
    background-color: #222;
    border: 1px solid #515151;
  }

  .figure-caption {
    color: #9e9e9e;
  }

  .table {
    --bs-table-color: #e1e1e1;
    --bs-table-bg: transparent;
    --bs-table-border-color: #515151;
    --bs-table-accent-bg: transparent;
    --bs-table-striped-color: #e1e1e1;
    --bs-table-striped-bg: rgba(250, 250, 250, 0.05);
    --bs-table-active-color: #e1e1e1;
    --bs-table-active-bg: rgba(250, 250, 250, 0.1);
    --bs-table-hover-color: #e1e1e1;
    --bs-table-hover-bg: rgba(250, 250, 250, 0.075);
    color: #e1e1e1;
    border-color: #515151;
  }
  .table > :not(:first-child) {
    border-top: 2px solid currentColor;
  }

  .table-primary {
    --bs-table-color: #fff;
    --bs-table-bg: #1c2d40;
    --bs-table-border-color: #334253;
    --bs-table-striped-bg: #27384a;
    --bs-table-striped-color: #fff;
    --bs-table-active-bg: #334253;
    --bs-table-active-color: #fff;
    --bs-table-hover-bg: #2d3d4e;
    --bs-table-hover-color: #fff;
    color: #fff;
    border-color: #334253;
  }

  .table-secondary {
    --bs-table-color: #fff;
    --bs-table-bg: #313131;
    --bs-table-border-color: #464646;
    --bs-table-striped-bg: #3b3b3b;
    --bs-table-striped-color: #fff;
    --bs-table-active-bg: #464646;
    --bs-table-active-color: #fff;
    --bs-table-hover-bg: #404040;
    --bs-table-hover-color: #fff;
    color: #fff;
    border-color: #464646;
  }

  .table-success {
    --bs-table-color: #fff;
    --bs-table-bg: #005e46;
    --bs-table-border-color: #1a6e59;
    --bs-table-striped-bg: #0d664f;
    --bs-table-striped-color: #fff;
    --bs-table-active-bg: #1a6e59;
    --bs-table-active-color: #fff;
    --bs-table-hover-bg: #136a54;
    --bs-table-hover-color: #fff;
    color: #fff;
    border-color: #1a6e59;
  }

  .table-info {
    --bs-table-color: #fff;
    --bs-table-bg: #0c515c;
    --bs-table-border-color: #24626c;
    --bs-table-striped-bg: #185a64;
    --bs-table-striped-color: #fff;
    --bs-table-active-bg: #24626c;
    --bs-table-active-color: #fff;
    --bs-table-hover-bg: #1e5e68;
    --bs-table-hover-color: #fff;
    color: #fff;
    border-color: #24626c;
  }

  .table-warning {
    --bs-table-color: #fff;
    --bs-table-bg: #7a4e09;
    --bs-table-border-color: #876022;
    --bs-table-striped-bg: #815715;
    --bs-table-striped-color: #fff;
    --bs-table-active-bg: #876022;
    --bs-table-active-color: #fff;
    --bs-table-hover-bg: #845b1b;
    --bs-table-hover-color: #fff;
    color: #fff;
    border-color: #876022;
  }

  .table-danger {
    --bs-table-color: #fff;
    --bs-table-bg: #74261e;
    --bs-table-border-color: #823c35;
    --bs-table-striped-bg: #7b3129;
    --bs-table-striped-color: #fff;
    --bs-table-active-bg: #823c35;
    --bs-table-active-color: #fff;
    --bs-table-hover-bg: #7e362f;
    --bs-table-hover-color: #fff;
    color: #fff;
    border-color: #823c35;
  }

  .table-light {
    --bs-table-color: #000;
    --bs-table-bg: #9e9e9e;
    --bs-table-border-color: #8e8e8e;
    --bs-table-striped-bg: #969696;
    --bs-table-striped-color: #000;
    --bs-table-active-bg: #8e8e8e;
    --bs-table-active-color: #000;
    --bs-table-hover-bg: #929292;
    --bs-table-hover-color: #000;
    color: #000;
    border-color: #8e8e8e;
  }

  .table-dark {
    --bs-table-color: #fff;
    --bs-table-bg: #3b3b3b;
    --bs-table-border-color: #4f4f4f;
    --bs-table-striped-bg: #454545;
    --bs-table-striped-color: #fff;
    --bs-table-active-bg: #4f4f4f;
    --bs-table-active-color: #fff;
    --bs-table-hover-bg: #4a4a4a;
    --bs-table-hover-color: #fff;
    color: #fff;
    border-color: #4f4f4f;
  }

  .form-text {
    color: #9e9e9e;
  }

  .form-control {
    color: #b1b1b1;
    background-color: #222;
    border: 1px solid #515151;
  }
  .form-control:focus {
    color: #b1b1b1;
    background-color: #222;
    border-color: #9badbf;
    box-shadow: 0 0 0 0.25rem rgba(55, 90, 127, 0.25);
  }
  .form-control::placeholder {
    color: #5a5a5a;
  }
  .form-control:disabled, .form-control[readonly] {
    background-color: #222;
  }
  .form-control::file-selector-button {
    color: #b1b1b1;
    background-color: #3b3b3b;
    border-color: inherit;
  }
  .form-control:hover:not(:disabled):not([readonly])::file-selector-button {
    background-color: #383838;
  }
  .form-control::-webkit-file-upload-button {
    color: #b1b1b1;
    background-color: #3b3b3b;
    border-color: inherit;
  }
  .form-control:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
    background-color: #383838;
  }

  .form-control-plaintext {
    color: #e1e1e1;
    background-color: transparent;
    border: solid transparent;
  }

  .form-select {
    color: #b1b1b1;
    background-color: #222;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23cfcfcf' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
    border: 1px solid #515151;
  }
  .form-select:focus {
    border-color: #9badbf;
    box-shadow: 0 0 0 0.25rem rgba(55, 90, 127, 0.25);
  }
  .form-select[multiple], .form-select[size]:not([size="1"]) {
    background-image: none;
  }
  .form-select:disabled {
    background-color: #3b3b3b;
  }
  .form-select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #b1b1b1;
  }

  .form-check-input {
    background-color: #222;
    border: 1px solid rgba(255, 255, 255, 0.25);
  }
  .form-check-input:active {
    filter: brightness(90%);
  }
  .form-check-input:focus {
    border-color: #9badbf;
    box-shadow: 0 0 0 0.25rem rgba(55, 90, 127, 0.25);
  }
  .form-check-input:checked {
    background-color: #375a7f;
    border-color: #375a7f;
  }
  .form-check-input:checked[type=checkbox] {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fafafa' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");
  }
  .form-check-input:checked[type=radio] {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fafafa'/%3e%3c/svg%3e");
  }
  .form-check-input[type=checkbox]:indeterminate {
    background-color: #375a7f;
    border-color: #375a7f;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fafafa' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
  }
  .form-check-input:disabled {
    opacity: 0.5;
  }
  .form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
    opacity: 0.5;
  }

  .form-switch .form-check-input {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%28250, 250, 250, 0.25%29'/%3e%3c/svg%3e");
  }
  .form-switch .form-check-input:focus {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%239badbf'/%3e%3c/svg%3e");
  }
  .form-switch .form-check-input:checked {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fafafa'/%3e%3c/svg%3e");
  }

  .btn-check[disabled] + .btn, .btn-check:disabled + .btn {
    opacity: 0.65;
  }

  .form-range:focus::-webkit-slider-thumb {
    box-shadow: 0 0 0 1px #222, 0 0 0 0.25rem rgba(55, 90, 127, 0.25);
  }
  .form-range:focus::-moz-range-thumb {
    box-shadow: 0 0 0 1px #222, 0 0 0 0.25rem rgba(55, 90, 127, 0.25);
  }
  .form-range::-webkit-slider-thumb {
    background-color: #375a7f;
    border: 0;
  }
  .form-range::-webkit-slider-thumb:active {
    background-color: #c3ced9;
  }
  .form-range::-webkit-slider-runnable-track {
    background-color: #515151;
  }
  .form-range::-moz-range-thumb {
    background-color: #375a7f;
    border: 0;
  }
  .form-range::-moz-range-thumb:active {
    background-color: #c3ced9;
  }
  .form-range::-moz-range-track {
    background-color: #515151;
  }
  .form-range:disabled {
    pointer-events: none;
  }
  .form-range:disabled::-webkit-slider-thumb {
    background-color: #7e7e7e;
  }
  .form-range:disabled::-moz-range-thumb {
    background-color: #7e7e7e;
  }

  .form-floating > .form-control::placeholder {
    color: transparent;
  }
  .form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-select ~ label {
    opacity: 0.65;
  }
  .form-floating > .form-control:-webkit-autofill ~ label {
    opacity: 0.65;
  }

  .input-group-text {
    color: #b1b1b1;
    background-color: #3b3b3b;
    border: 1px solid #515151;
  }

  .valid-feedback {
    color: #00bc8c;
  }

  .valid-tooltip {
    color: #111;
    background-color: rgba(0, 188, 140, 0.9);
  }

  .was-validated .form-control:valid, .form-control.is-valid {
    border-color: #00bc8c;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2300bc8c' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus {
    border-color: #00bc8c;
    box-shadow: 0 0 0 0.25rem rgba(0, 188, 140, 0.25);
  }

  .was-validated .form-select:valid, .form-select.is-valid {
    border-color: #00bc8c;
  }
  .was-validated .form-select:valid:not([multiple]):not([size]), .was-validated .form-select:valid:not([multiple])[size="1"], .form-select.is-valid:not([multiple]):not([size]), .form-select.is-valid:not([multiple])[size="1"] {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23cfcfcf' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2300bc8c' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  }
  .was-validated .form-select:valid[multiple], .was-validated .form-select:valid[size]:not([size="1"]), .form-select.is-valid[multiple], .form-select.is-valid[size]:not([size="1"]) {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2300bc8c' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  }
  .was-validated .form-select:valid:focus, .form-select.is-valid:focus {
    border-color: #00bc8c;
    box-shadow: 0 0 0 0.25rem rgba(0, 188, 140, 0.25);
  }

  .was-validated .form-check-input:valid, .form-check-input.is-valid {
    border-color: #00bc8c;
  }
  .was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
    background-color: #00bc8c;
  }
  .was-validated .form-check-input:valid:focus, .form-check-input.is-valid:focus {
    box-shadow: 0 0 0 0.25rem rgba(0, 188, 140, 0.25);
  }
  .was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
    color: #00bc8c;
  }

  .invalid-feedback {
    color: #e74c3c;
  }

  .invalid-tooltip {
    color: #fafafa;
    background-color: rgba(231, 76, 60, 0.9);
  }

  .was-validated .form-control:invalid, .form-control.is-invalid {
    border-color: #e74c3c;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23e74c3c'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
    border-color: #e74c3c;
    box-shadow: 0 0 0 0.25rem rgba(231, 76, 60, 0.25);
  }

  .was-validated .form-select:invalid, .form-select.is-invalid {
    border-color: #e74c3c;
  }
  .was-validated .form-select:invalid:not([multiple]):not([size]), .was-validated .form-select:invalid:not([multiple])[size="1"], .form-select.is-invalid:not([multiple]):not([size]), .form-select.is-invalid:not([multiple])[size="1"] {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23cfcfcf' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23e74c3c'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  }
  .was-validated .form-select:invalid[multiple], .was-validated .form-select:invalid[size]:not([size="1"]), .form-select.is-invalid[multiple], .form-select.is-invalid[size]:not([size="1"]) {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23e74c3c'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  }
  .was-validated .form-select:invalid:focus, .form-select.is-invalid:focus {
    border-color: #e74c3c;
    box-shadow: 0 0 0 0.25rem rgba(231, 76, 60, 0.25);
  }

  .was-validated .form-check-input:invalid, .form-check-input.is-invalid {
    border-color: #e74c3c;
  }
  .was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked {
    background-color: #e74c3c;
  }
  .was-validated .form-check-input:invalid:focus, .form-check-input.is-invalid:focus {
    box-shadow: 0 0 0 0.25rem rgba(231, 76, 60, 0.25);
  }
  .was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
    color: #e74c3c;
  }

  .btn {
    color: #e1e1e1;
    background-color: transparent;
    border: 1px solid transparent;
  }
  .btn:hover {
    color: #e1e1e1;
  }
  .btn-check:focus + .btn, .btn:focus {
    box-shadow: 0 0 0 0.25rem rgba(55, 90, 127, 0.25);
  }
  .btn:disabled, .btn.disabled, fieldset:disabled .btn {
    opacity: 0.65;
  }

  .btn-primary {
    color: #fafafa;
    background-color: #375a7f;
    border-color: #375a7f;
  }
  .btn-primary:hover {
    color: #fafafa;
    background-color: #2f4d6c;
    border-color: #2c4866;
  }
  .btn-check:focus + .btn-primary, .btn-primary:focus {
    color: #fafafa;
    background-color: #2f4d6c;
    border-color: #2c4866;
    box-shadow: 0 0 0 0.25rem rgba(84, 114, 145, 0.5);
  }
  .btn-check:checked + .btn-primary, .btn-check:active + .btn-primary, .btn-primary:active, .btn-primary.active, .show > .btn-primary.dropdown-toggle {
    color: #fafafa;
    background-color: #2c4866;
    border-color: #29445f;
  }
  .btn-check:checked + .btn-primary:focus, .btn-check:active + .btn-primary:focus, .btn-primary:active:focus, .btn-primary.active:focus, .show > .btn-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.25rem rgba(84, 114, 145, 0.5);
  }
  .btn-primary:disabled, .btn-primary.disabled {
    color: #fafafa;
    background-color: #375a7f;
    border-color: #375a7f;
  }

  .btn-secondary {
    color: #fafafa;
    background-color: #626262;
    border-color: #626262;
  }
  .btn-secondary:hover {
    color: #fafafa;
    background-color: #535353;
    border-color: #4e4e4e;
  }
  .btn-check:focus + .btn-secondary, .btn-secondary:focus {
    color: #fafafa;
    background-color: #535353;
    border-color: #4e4e4e;
    box-shadow: 0 0 0 0.25rem rgba(121, 121, 121, 0.5);
  }
  .btn-check:checked + .btn-secondary, .btn-check:active + .btn-secondary, .btn-secondary:active, .btn-secondary.active, .show > .btn-secondary.dropdown-toggle {
    color: #fafafa;
    background-color: #4e4e4e;
    border-color: #4a4a4a;
  }
  .btn-check:checked + .btn-secondary:focus, .btn-check:active + .btn-secondary:focus, .btn-secondary:active:focus, .btn-secondary.active:focus, .show > .btn-secondary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.25rem rgba(121, 121, 121, 0.5);
  }
  .btn-secondary:disabled, .btn-secondary.disabled {
    color: #fafafa;
    background-color: #626262;
    border-color: #626262;
  }

  .btn-success {
    color: #111;
    background-color: #00bc8c;
    border-color: #00bc8c;
  }
  .btn-success:hover {
    color: #111;
    background-color: #26c69d;
    border-color: #1ac398;
  }
  .btn-check:focus + .btn-success, .btn-success:focus {
    color: #111;
    background-color: #26c69d;
    border-color: #1ac398;
    box-shadow: 0 0 0 0.25rem rgba(3, 162, 122, 0.5);
  }
  .btn-check:checked + .btn-success, .btn-check:active + .btn-success, .btn-success:active, .btn-success.active, .show > .btn-success.dropdown-toggle {
    color: #111;
    background-color: #33c9a3;
    border-color: #1ac398;
  }
  .btn-check:checked + .btn-success:focus, .btn-check:active + .btn-success:focus, .btn-success:active:focus, .btn-success.active:focus, .show > .btn-success.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.25rem rgba(3, 162, 122, 0.5);
  }
  .btn-success:disabled, .btn-success.disabled {
    color: #111;
    background-color: #00bc8c;
    border-color: #00bc8c;
  }

  .btn-info {
    color: #fafafa;
    background-color: #17a2b8;
    border-color: #17a2b8;
  }
  .btn-info:hover {
    color: #fafafa;
    background-color: #148a9c;
    border-color: #128293;
  }
  .btn-check:focus + .btn-info, .btn-info:focus {
    color: #fafafa;
    background-color: #148a9c;
    border-color: #128293;
    box-shadow: 0 0 0 0.25rem rgba(57, 175, 194, 0.5);
  }
  .btn-check:checked + .btn-info, .btn-check:active + .btn-info, .btn-info:active, .btn-info.active, .show > .btn-info.dropdown-toggle {
    color: #fafafa;
    background-color: #128293;
    border-color: #117a8a;
  }
  .btn-check:checked + .btn-info:focus, .btn-check:active + .btn-info:focus, .btn-info:active:focus, .btn-info.active:focus, .show > .btn-info.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.25rem rgba(57, 175, 194, 0.5);
  }
  .btn-info:disabled, .btn-info.disabled {
    color: #fafafa;
    background-color: #17a2b8;
    border-color: #17a2b8;
  }

  .btn-warning {
    color: #111;
    background-color: #f39c12;
    border-color: #f39c12;
  }
  .btn-warning:hover {
    color: #111;
    background-color: #f5ab36;
    border-color: #f4a62a;
  }
  .btn-check:focus + .btn-warning, .btn-warning:focus {
    color: #111;
    background-color: #f5ab36;
    border-color: #f4a62a;
    box-shadow: 0 0 0 0.25rem rgba(209, 135, 18, 0.5);
  }
  .btn-check:checked + .btn-warning, .btn-check:active + .btn-warning, .btn-warning:active, .btn-warning.active, .show > .btn-warning.dropdown-toggle {
    color: #111;
    background-color: #f5b041;
    border-color: #f4a62a;
  }
  .btn-check:checked + .btn-warning:focus, .btn-check:active + .btn-warning:focus, .btn-warning:active:focus, .btn-warning.active:focus, .show > .btn-warning.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.25rem rgba(209, 135, 18, 0.5);
  }
  .btn-warning:disabled, .btn-warning.disabled {
    color: #111;
    background-color: #f39c12;
    border-color: #f39c12;
  }

  .btn-danger {
    color: #fafafa;
    background-color: #e74c3c;
    border-color: #e74c3c;
  }
  .btn-danger:hover {
    color: #fafafa;
    background-color: #c44133;
    border-color: #b93d30;
  }
  .btn-check:focus + .btn-danger, .btn-danger:focus {
    color: #fafafa;
    background-color: #c44133;
    border-color: #b93d30;
    box-shadow: 0 0 0 0.25rem rgba(234, 102, 89, 0.5);
  }
  .btn-check:checked + .btn-danger, .btn-check:active + .btn-danger, .btn-danger:active, .btn-danger.active, .show > .btn-danger.dropdown-toggle {
    color: #fafafa;
    background-color: #b93d30;
    border-color: #ad392d;
  }
  .btn-check:checked + .btn-danger:focus, .btn-check:active + .btn-danger:focus, .btn-danger:active:focus, .btn-danger.active:focus, .show > .btn-danger.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.25rem rgba(234, 102, 89, 0.5);
  }
  .btn-danger:disabled, .btn-danger.disabled {
    color: #fafafa;
    background-color: #e74c3c;
    border-color: #e74c3c;
  }

  .btn-light {
    color: #fafafa;
    background-color: #9e9e9e;
    border-color: #9e9e9e;
  }
  .btn-light:hover {
    color: #fafafa;
    background-color: #868686;
    border-color: #7e7e7e;
  }
  .btn-check:focus + .btn-light, .btn-light:focus {
    color: #fafafa;
    background-color: #868686;
    border-color: #7e7e7e;
    box-shadow: 0 0 0 0.25rem rgba(172, 172, 172, 0.5);
  }
  .btn-check:checked + .btn-light, .btn-check:active + .btn-light, .btn-light:active, .btn-light.active, .show > .btn-light.dropdown-toggle {
    color: #fafafa;
    background-color: #7e7e7e;
    border-color: #777777;
  }
  .btn-check:checked + .btn-light:focus, .btn-check:active + .btn-light:focus, .btn-light:active:focus, .btn-light.active:focus, .show > .btn-light.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.25rem rgba(172, 172, 172, 0.5);
  }
  .btn-light:disabled, .btn-light.disabled {
    color: #fafafa;
    background-color: #9e9e9e;
    border-color: #9e9e9e;
  }

  .btn-dark {
    color: #fafafa;
    background-color: #3b3b3b;
    border-color: #3b3b3b;
  }
  .btn-dark:hover {
    color: #fafafa;
    background-color: #323232;
    border-color: #2f2f2f;
  }
  .btn-check:focus + .btn-dark, .btn-dark:focus {
    color: #fafafa;
    background-color: #323232;
    border-color: #2f2f2f;
    box-shadow: 0 0 0 0.25rem rgba(88, 88, 88, 0.5);
  }
  .btn-check:checked + .btn-dark, .btn-check:active + .btn-dark, .btn-dark:active, .btn-dark.active, .show > .btn-dark.dropdown-toggle {
    color: #fafafa;
    background-color: #2f2f2f;
    border-color: #2c2c2c;
  }
  .btn-check:checked + .btn-dark:focus, .btn-check:active + .btn-dark:focus, .btn-dark:active:focus, .btn-dark.active:focus, .show > .btn-dark.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.25rem rgba(88, 88, 88, 0.5);
  }
  .btn-dark:disabled, .btn-dark.disabled {
    color: #fafafa;
    background-color: #3b3b3b;
    border-color: #3b3b3b;
  }

  .btn-outline-primary {
    color: #375a7f;
    border-color: #375a7f;
  }
  .btn-outline-primary:hover {
    color: #fff;
    background-color: #375a7f;
    border-color: #375a7f;
  }
  .btn-check:focus + .btn-outline-primary, .btn-outline-primary:focus {
    box-shadow: 0 0 0 0.25rem rgba(55, 90, 127, 0.5);
  }
  .btn-check:checked + .btn-outline-primary, .btn-check:active + .btn-outline-primary, .btn-outline-primary:active, .btn-outline-primary.active, .btn-outline-primary.dropdown-toggle.show {
    color: #fff;
    background-color: #375a7f;
    border-color: #375a7f;
  }
  .btn-check:checked + .btn-outline-primary:focus, .btn-check:active + .btn-outline-primary:focus, .btn-outline-primary:active:focus, .btn-outline-primary.active:focus, .btn-outline-primary.dropdown-toggle.show:focus {
    box-shadow: 0 0 0 0.25rem rgba(55, 90, 127, 0.5);
  }
  .btn-outline-primary:disabled, .btn-outline-primary.disabled {
    color: #375a7f;
    background-color: transparent;
  }

  .btn-outline-secondary {
    color: #626262;
    border-color: #626262;
  }
  .btn-outline-secondary:hover {
    color: #fff;
    background-color: #626262;
    border-color: #626262;
  }
  .btn-check:focus + .btn-outline-secondary, .btn-outline-secondary:focus {
    box-shadow: 0 0 0 0.25rem rgba(98, 98, 98, 0.5);
  }
  .btn-check:checked + .btn-outline-secondary, .btn-check:active + .btn-outline-secondary, .btn-outline-secondary:active, .btn-outline-secondary.active, .btn-outline-secondary.dropdown-toggle.show {
    color: #fff;
    background-color: #626262;
    border-color: #626262;
  }
  .btn-check:checked + .btn-outline-secondary:focus, .btn-check:active + .btn-outline-secondary:focus, .btn-outline-secondary:active:focus, .btn-outline-secondary.active:focus, .btn-outline-secondary.dropdown-toggle.show:focus {
    box-shadow: 0 0 0 0.25rem rgba(98, 98, 98, 0.5);
  }
  .btn-outline-secondary:disabled, .btn-outline-secondary.disabled {
    color: #626262;
    background-color: transparent;
  }

  .btn-outline-success {
    color: #00bc8c;
    border-color: #00bc8c;
  }
  .btn-outline-success:hover {
    color: #000;
    background-color: #00bc8c;
    border-color: #00bc8c;
  }
  .btn-check:focus + .btn-outline-success, .btn-outline-success:focus {
    box-shadow: 0 0 0 0.25rem rgba(0, 188, 140, 0.5);
  }
  .btn-check:checked + .btn-outline-success, .btn-check:active + .btn-outline-success, .btn-outline-success:active, .btn-outline-success.active, .btn-outline-success.dropdown-toggle.show {
    color: #000;
    background-color: #00bc8c;
    border-color: #00bc8c;
  }
  .btn-check:checked + .btn-outline-success:focus, .btn-check:active + .btn-outline-success:focus, .btn-outline-success:active:focus, .btn-outline-success.active:focus, .btn-outline-success.dropdown-toggle.show:focus {
    box-shadow: 0 0 0 0.25rem rgba(0, 188, 140, 0.5);
  }
  .btn-outline-success:disabled, .btn-outline-success.disabled {
    color: #00bc8c;
    background-color: transparent;
  }

  .btn-outline-info {
    color: #17a2b8;
    border-color: #17a2b8;
  }
  .btn-outline-info:hover {
    color: #000;
    background-color: #17a2b8;
    border-color: #17a2b8;
  }
  .btn-check:focus + .btn-outline-info, .btn-outline-info:focus {
    box-shadow: 0 0 0 0.25rem rgba(23, 162, 184, 0.5);
  }
  .btn-check:checked + .btn-outline-info, .btn-check:active + .btn-outline-info, .btn-outline-info:active, .btn-outline-info.active, .btn-outline-info.dropdown-toggle.show {
    color: #000;
    background-color: #17a2b8;
    border-color: #17a2b8;
  }
  .btn-check:checked + .btn-outline-info:focus, .btn-check:active + .btn-outline-info:focus, .btn-outline-info:active:focus, .btn-outline-info.active:focus, .btn-outline-info.dropdown-toggle.show:focus {
    box-shadow: 0 0 0 0.25rem rgba(23, 162, 184, 0.5);
  }
  .btn-outline-info:disabled, .btn-outline-info.disabled {
    color: #17a2b8;
    background-color: transparent;
  }

  .btn-outline-warning {
    color: #f39c12;
    border-color: #f39c12;
  }
  .btn-outline-warning:hover {
    color: #000;
    background-color: #f39c12;
    border-color: #f39c12;
  }
  .btn-check:focus + .btn-outline-warning, .btn-outline-warning:focus {
    box-shadow: 0 0 0 0.25rem rgba(243, 156, 18, 0.5);
  }
  .btn-check:checked + .btn-outline-warning, .btn-check:active + .btn-outline-warning, .btn-outline-warning:active, .btn-outline-warning.active, .btn-outline-warning.dropdown-toggle.show {
    color: #000;
    background-color: #f39c12;
    border-color: #f39c12;
  }
  .btn-check:checked + .btn-outline-warning:focus, .btn-check:active + .btn-outline-warning:focus, .btn-outline-warning:active:focus, .btn-outline-warning.active:focus, .btn-outline-warning.dropdown-toggle.show:focus {
    box-shadow: 0 0 0 0.25rem rgba(243, 156, 18, 0.5);
  }
  .btn-outline-warning:disabled, .btn-outline-warning.disabled {
    color: #f39c12;
    background-color: transparent;
  }

  .btn-outline-danger {
    color: #e74c3c;
    border-color: #e74c3c;
  }
  .btn-outline-danger:hover {
    color: #000;
    background-color: #e74c3c;
    border-color: #e74c3c;
  }
  .btn-check:focus + .btn-outline-danger, .btn-outline-danger:focus {
    box-shadow: 0 0 0 0.25rem rgba(231, 76, 60, 0.5);
  }
  .btn-check:checked + .btn-outline-danger, .btn-check:active + .btn-outline-danger, .btn-outline-danger:active, .btn-outline-danger.active, .btn-outline-danger.dropdown-toggle.show {
    color: #000;
    background-color: #e74c3c;
    border-color: #e74c3c;
  }
  .btn-check:checked + .btn-outline-danger:focus, .btn-check:active + .btn-outline-danger:focus, .btn-outline-danger:active:focus, .btn-outline-danger.active:focus, .btn-outline-danger.dropdown-toggle.show:focus {
    box-shadow: 0 0 0 0.25rem rgba(231, 76, 60, 0.5);
  }
  .btn-outline-danger:disabled, .btn-outline-danger.disabled {
    color: #e74c3c;
    background-color: transparent;
  }

  .btn-outline-light {
    color: #9e9e9e;
    border-color: #9e9e9e;
  }
  .btn-outline-light:hover {
    color: #000;
    background-color: #9e9e9e;
    border-color: #9e9e9e;
  }
  .btn-check:focus + .btn-outline-light, .btn-outline-light:focus {
    box-shadow: 0 0 0 0.25rem rgba(158, 158, 158, 0.5);
  }
  .btn-check:checked + .btn-outline-light, .btn-check:active + .btn-outline-light, .btn-outline-light:active, .btn-outline-light.active, .btn-outline-light.dropdown-toggle.show {
    color: #000;
    background-color: #9e9e9e;
    border-color: #9e9e9e;
  }
  .btn-check:checked + .btn-outline-light:focus, .btn-check:active + .btn-outline-light:focus, .btn-outline-light:active:focus, .btn-outline-light.active:focus, .btn-outline-light.dropdown-toggle.show:focus {
    box-shadow: 0 0 0 0.25rem rgba(158, 158, 158, 0.5);
  }
  .btn-outline-light:disabled, .btn-outline-light.disabled {
    color: #9e9e9e;
    background-color: transparent;
  }

  .btn-outline-dark {
    color: #3b3b3b;
    border-color: #3b3b3b;
  }
  .btn-outline-dark:hover {
    color: #fff;
    background-color: #3b3b3b;
    border-color: #3b3b3b;
  }
  .btn-check:focus + .btn-outline-dark, .btn-outline-dark:focus {
    box-shadow: 0 0 0 0.25rem rgba(59, 59, 59, 0.5);
  }
  .btn-check:checked + .btn-outline-dark, .btn-check:active + .btn-outline-dark, .btn-outline-dark:active, .btn-outline-dark.active, .btn-outline-dark.dropdown-toggle.show {
    color: #fff;
    background-color: #3b3b3b;
    border-color: #3b3b3b;
  }
  .btn-check:checked + .btn-outline-dark:focus, .btn-check:active + .btn-outline-dark:focus, .btn-outline-dark:active:focus, .btn-outline-dark.active:focus, .btn-outline-dark.dropdown-toggle.show:focus {
    box-shadow: 0 0 0 0.25rem rgba(59, 59, 59, 0.5);
  }
  .btn-outline-dark:disabled, .btn-outline-dark.disabled {
    color: #3b3b3b;
    background-color: transparent;
  }

  .btn-link {
    color: #5f7b99;
  }
  .btn-link:hover {
    color: #4b6b8c;
  }
  .btn-link:disabled, .btn-link.disabled {
    color: #626262;
  }

  .dropdown-menu {
    color: #e1e1e1;
    background-color: #111;
    border: 1px solid rgba(250, 250, 250, 0.15);
  }

  .dropdown-divider {
    border-top: 1px solid rgba(250, 250, 250, 0.15);
  }

  .dropdown-item {
    color: #f8f9fa;
    background-color: transparent;
    border: 0;
  }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #dfe0e1;
    background-color: #343a40;
  }
  .dropdown-item.active, .dropdown-item:active {
    color: #fafafa;
    background-color: #375a7f;
  }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #7e7e7e;
    background-color: transparent;
  }

  .dropdown-header {
    color: #9e9e9e;
  }

  .dropdown-item-text {
    color: #f8f9fa;
  }

  .dropdown-menu-dark {
    color: #515151;
    background-color: #cfcfcf;
    border-color: rgba(250, 250, 250, 0.15);
  }
  .dropdown-menu-dark .dropdown-item {
    color: #515151;
  }
  .dropdown-menu-dark .dropdown-item:hover, .dropdown-menu-dark .dropdown-item:focus {
    color: #111;
    background-color: rgba(17, 17, 17, 0.15);
  }
  .dropdown-menu-dark .dropdown-item.active, .dropdown-menu-dark .dropdown-item:active {
    color: #fafafa;
    background-color: #375a7f;
  }
  .dropdown-menu-dark .dropdown-item.disabled, .dropdown-menu-dark .dropdown-item:disabled {
    color: #7e7e7e;
  }
  .dropdown-menu-dark .dropdown-divider {
    border-color: rgba(250, 250, 250, 0.15);
  }
  .dropdown-menu-dark .dropdown-item-text {
    color: #515151;
  }
  .dropdown-menu-dark .dropdown-header {
    color: #7e7e7e;
  }

  .nav-link {
    color: #5f7b99;
  }
  .nav-link:hover, .nav-link:focus {
    color: #4b6b8c;
  }
  .nav-link.disabled {
    color: #9e9e9e;
  }

  .nav-tabs {
    border-bottom: 1px solid #515151;
  }
  .nav-tabs .nav-link {
    border: 1px solid transparent;
  }
  .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
    border-color: #3b3b3b #3b3b3b #515151;
  }
  .nav-tabs .nav-link.disabled {
    color: #9e9e9e;
    background-color: transparent;
    border-color: transparent;
  }
  .nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
    color: #b1b1b1;
    background-color: #222;
    border-color: #515151 #515151 #222;
  }

  .nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
    color: #fafafa;
    background-color: #375a7f;
  }

  .navbar-toggler {
    background-color: transparent;
    border: 1px solid transparent;
  }

  .navbar-light .navbar-brand {
    color: rgba(250, 250, 250, 0.9);
  }
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(250, 250, 250, 0.9);
  }
  .navbar-light .navbar-nav .nav-link {
    color: rgba(250, 250, 250, 0.55);
  }
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: rgba(250, 250, 250, 0.7);
  }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(250, 250, 250, 0.3);
  }
  .navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .nav-link.active {
    color: rgba(250, 250, 250, 0.9);
  }
  .navbar-light .navbar-toggler {
    color: rgba(250, 250, 250, 0.55);
    border-color: rgba(250, 250, 250, 0.1);
  }
  .navbar-light .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28250, 250, 250, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  }
  .navbar-light .navbar-text {
    color: rgba(250, 250, 250, 0.55);
  }
  .navbar-light .navbar-text a,
.navbar-light .navbar-text a:hover,
.navbar-light .navbar-text a:focus {
    color: rgba(250, 250, 250, 0.9);
  }

  .navbar-dark .navbar-brand {
    color: #fafafa;
  }
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #fafafa;
  }
  .navbar-dark .navbar-nav .nav-link {
    color: rgba(250, 250, 250, 0.55);
  }
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(250, 250, 250, 0.75);
  }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(250, 250, 250, 0.25);
  }
  .navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .nav-link.active {
    color: #fafafa;
  }
  .navbar-dark .navbar-toggler {
    color: rgba(250, 250, 250, 0.55);
    border-color: rgba(250, 250, 250, 0.1);
  }
  .navbar-dark .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28250, 250, 250, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  }
  .navbar-dark .navbar-text {
    color: rgba(250, 250, 250, 0.55);
  }
  .navbar-dark .navbar-text a,
.navbar-dark .navbar-text a:hover,
.navbar-dark .navbar-text a:focus {
    color: #fafafa;
  }

  .card {
    background-color: #222;
    border: 1px solid rgba(250, 250, 250, 0.125);
  }

  .card-header {
    background-color: rgba(250, 250, 250, 0.03);
    border-bottom: 1px solid rgba(250, 250, 250, 0.125);
  }

  .card-footer {
    background-color: rgba(250, 250, 250, 0.03);
    border-top: 1px solid rgba(250, 250, 250, 0.125);
  }

  .accordion-button {
    color: #e1e1e1;
    background-color: #222;
  }
  .accordion-button:not(.collapsed) {
    color: #879cb2;
    background-color: #1c2d40;
    box-shadow: inset 0 -1px 0 rgba(250, 250, 250, 0.125);
  }
  .accordion-button:not(.collapsed)::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23879cb2'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  }
  .accordion-button::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23e1e1e1'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  }
  .accordion-button:hover {
    z-index: 2;
  }
  .accordion-button:focus {
    border-color: #9badbf;
    box-shadow: 0 0 0 0.25rem rgba(55, 90, 127, 0.25);
  }

  .accordion-item {
    background-color: #222;
    border: 1px solid rgba(250, 250, 250, 0.125);
  }

  .accordion-flush .accordion-collapse {
    border-width: 0;
  }
  .accordion-flush .accordion-item {
    border-right: 0;
    border-left: 0;
  }
  .accordion-flush .accordion-item:first-child {
    border-top: 0;
  }
  .accordion-flush .accordion-item:last-child {
    border-bottom: 0;
  }

  .breadcrumb-item + .breadcrumb-item::before {
    color: #9e9e9e;
  }
  .breadcrumb-item.active {
    color: #9e9e9e;
  }

  .page-link {
    color: #5f7b99;
    background-color: #3b3b3b;
    border: 1px solid #515151;
  }
  .page-link:hover {
    color: #4b6b8c;
    background-color: #515151;
    border-color: #515151;
  }
  .page-link:focus {
    color: #4b6b8c;
    background-color: #3b3b3b;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(55, 90, 127, 0.25);
  }

  .page-item.active .page-link {
    color: #fafafa;
    background-color: #375a7f;
    border-color: #375a7f;
  }
  .page-item.disabled .page-link {
    color: #5a5a5a;
    background-color: #222;
    border-color: #515151;
  }

  .badge {
    color: #fafafa;
  }

  .alert-heading {
    color: inherit;
  }

  .alert-primary {
    color: #738ca5;
    background-color: #1c2d40;
    border-color: #21364c;
  }
  .alert-primary .alert-link {
    color: #5c7084;
  }

  .alert-secondary {
    color: #919191;
    background-color: #313131;
    border-color: #3b3b3b;
  }
  .alert-secondary .alert-link {
    color: #747474;
  }

  .alert-success {
    color: #4dd0af;
    background-color: #005e46;
    border-color: #007154;
  }
  .alert-success .alert-link {
    color: #3ea68c;
  }

  .alert-info {
    color: #5dbecd;
    background-color: #0c515c;
    border-color: #0e616e;
  }
  .alert-info .alert-link {
    color: #4a98a4;
  }

  .alert-warning {
    color: #f7ba59;
    background-color: #7a4e09;
    border-color: #925e0b;
  }
  .alert-warning .alert-link {
    color: #c69547;
  }

  .alert-danger {
    color: #ee8277;
    background-color: #74261e;
    border-color: #8b2e24;
  }
  .alert-danger .alert-link {
    color: #be685f;
  }

  .alert-light {
    color: #bbbbbb;
    background-color: #4f4f4f;
    border-color: #5f5f5f;
  }
  .alert-light .alert-link {
    color: #969696;
  }

  .alert-dark {
    color: #767676;
    background-color: #1e1e1e;
    border-color: #232323;
  }
  .alert-dark .alert-link {
    color: #5e5e5e;
  }

  .progress {
    background-color: #3b3b3b;
  }

  .progress-bar {
    color: #111;
    background-color: #375a7f;
  }

  .list-group-item-action {
    color: #b1b1b1;
  }
  .list-group-item-action:hover, .list-group-item-action:focus {
    color: #b1b1b1;
    background-color: #2f2f2f;
  }
  .list-group-item-action:active {
    color: #e1e1e1;
    background-color: #3b3b3b;
  }

  .list-group-item {
    color: #e1e1e1;
    background-color: #222;
    border: 1px solid rgba(250, 250, 250, 0.125);
  }
  .list-group-item.disabled, .list-group-item:disabled {
    color: #9e9e9e;
    background-color: #222;
  }
  .list-group-item.active {
    color: #fafafa;
    background-color: #375a7f;
    border-color: #375a7f;
  }

  .list-group-flush > .list-group-item {
    border-width: 0 0 1px;
  }
  .list-group-flush > .list-group-item:last-child {
    border-bottom-width: 0;
  }

  .list-group-item-primary {
    color: #738ca5;
    background-color: #1c2d40;
  }
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #738ca5;
    background-color: #19293a;
  }
  .list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #738ca5;
    border-color: #738ca5;
  }

  .list-group-item-secondary {
    color: #919191;
    background-color: #313131;
  }
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #919191;
    background-color: #2c2c2c;
  }
  .list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #919191;
    border-color: #919191;
  }

  .list-group-item-success {
    color: #4dd0af;
    background-color: #005e46;
  }
  .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #4dd0af;
    background-color: #00553f;
  }
  .list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #4dd0af;
    border-color: #4dd0af;
  }

  .list-group-item-info {
    color: #5dbecd;
    background-color: #0c515c;
  }
  .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #5dbecd;
    background-color: #0b4953;
  }
  .list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #5dbecd;
    border-color: #5dbecd;
  }

  .list-group-item-warning {
    color: #f7ba59;
    background-color: #7a4e09;
  }
  .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #f7ba59;
    background-color: #6e4608;
  }
  .list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #f7ba59;
    border-color: #f7ba59;
  }

  .list-group-item-danger {
    color: #ee8277;
    background-color: #74261e;
  }
  .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #ee8277;
    background-color: #68221b;
  }
  .list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #ee8277;
    border-color: #ee8277;
  }

  .list-group-item-light {
    color: #bbbbbb;
    background-color: #4f4f4f;
  }
  .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #bbbbbb;
    background-color: #474747;
  }
  .list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #bbbbbb;
    border-color: #bbbbbb;
  }

  .list-group-item-dark {
    color: #767676;
    background-color: #1e1e1e;
  }
  .list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: #767676;
    background-color: #1b1b1b;
  }
  .list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #767676;
    border-color: #767676;
  }

  .btn-close {
    color: #fafafa;
    background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fafafa'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
    opacity: 0.5;
  }
  .btn-close:hover {
    color: #fafafa;
    opacity: 0.75;
  }
  .btn-close:focus {
    box-shadow: 0 0 0 0.25rem rgba(55, 90, 127, 0.25);
    opacity: 1;
  }
  .btn-close:disabled, .btn-close.disabled {
    opacity: 0.25;
  }

  .btn-close-white {
    filter: invert(1) grayscale(100%) brightness(200%);
  }

  .toast {
    background-color: rgba(17, 17, 17, 0.85);
    border: 1px solid rgba(250, 250, 250, 0.1);
    box-shadow: 0 0.5rem 1rem rgba(17, 17, 17, 0.15);
  }

  .toast-header {
    color: #9e9e9e;
    background-color: rgba(17, 17, 17, 0.85);
    border-bottom: 1px solid rgba(250, 250, 250, 0.05);
  }

  .modal-content {
    background-color: #2f2f2f;
    border: 1px solid rgba(250, 250, 250, 0.2);
  }

  .modal-backdrop {
    background-color: #111;
  }
  .modal-backdrop.fade {
    opacity: 0;
  }
  .modal-backdrop.show {
    opacity: 0.85;
  }

  .modal-header {
    border-bottom: 1px solid #515151;
  }

  .modal-footer {
    border-top: 1px solid #515151;
  }

  .tooltip {
    opacity: 0;
  }
  .tooltip.show {
    opacity: 0.9;
  }

  .bs-tooltip-top .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before {
    border-top-color: #fafafa;
  }

  .bs-tooltip-end .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before {
    border-right-color: #fafafa;
  }

  .bs-tooltip-bottom .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before {
    border-bottom-color: #fafafa;
  }

  .bs-tooltip-start .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before {
    border-left-color: #fafafa;
  }

  .tooltip-inner {
    color: #111;
    background-color: #fafafa;
  }

  .popover {
    background-color: #111;
    border: 1px solid rgba(250, 250, 250, 0.2);
  }
  .popover .popover-arrow::before, .popover .popover-arrow::after {
    border-color: transparent;
    border-style: solid;
  }

  .bs-popover-top > .popover-arrow::before,
.bs-popover-auto[data-popper-placement^=top] > .popover-arrow::before {
    border-top-color: rgba(250, 250, 250, 0.25);
  }
  .bs-popover-top > .popover-arrow::after,
.bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after {
    border-top-color: #111;
  }

  .bs-popover-end > .popover-arrow::before,
.bs-popover-auto[data-popper-placement^=right] > .popover-arrow::before {
    border-right-color: rgba(250, 250, 250, 0.25);
  }
  .bs-popover-end > .popover-arrow::after,
.bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after {
    border-right-color: #111;
  }

  .bs-popover-bottom > .popover-arrow::before,
.bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::before {
    border-bottom-color: rgba(250, 250, 250, 0.25);
  }
  .bs-popover-bottom > .popover-arrow::after,
.bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after {
    border-bottom-color: #111;
  }
  .bs-popover-bottom .popover-header::before,
.bs-popover-auto[data-popper-placement^=bottom] .popover-header::before {
    border-bottom: 1px solid #1f1f1f;
  }

  .bs-popover-start > .popover-arrow::before,
.bs-popover-auto[data-popper-placement^=left] > .popover-arrow::before {
    border-left-color: rgba(250, 250, 250, 0.25);
  }
  .bs-popover-start > .popover-arrow::after,
.bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after {
    border-left-color: #111;
  }

  .popover-header {
    background-color: #1f1f1f;
    border-bottom: 1px solid rgba(250, 250, 250, 0.2);
  }

  .popover-body {
    color: #e1e1e1;
  }

  .carousel-control-prev,
.carousel-control-next {
    color: #fafafa;
    opacity: 0.5;
  }
  .carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
    color: #fafafa;
    opacity: 0.9;
  }

  .carousel-control-prev-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fafafa'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
  }

  .carousel-control-next-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fafafa'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  }

  .carousel-indicators [data-bs-target] {
    background-color: #111;
    opacity: 0.5;
  }
  .carousel-indicators .active {
    opacity: 1;
  }

  .carousel-caption {
    color: #111;
  }

  .carousel-dark .carousel-control-prev-icon,
.carousel-dark .carousel-control-next-icon {
    filter: invert(1) grayscale(100);
  }
  .carousel-dark .carousel-indicators [data-bs-target] {
    background-color: #fafafa;
  }
  .carousel-dark .carousel-caption {
    color: #fafafa;
  }

  .offcanvas {
    background-color: #2f2f2f;
  }

  .offcanvas-backdrop {
    background-color: #111;
  }
  .offcanvas-backdrop.fade {
    opacity: 0;
  }
  .offcanvas-backdrop.show {
    opacity: 0.85;
  }

  .offcanvas-start {
    border-right: 1px solid rgba(250, 250, 250, 0.2);
  }

  .offcanvas-end {
    border-left: 1px solid rgba(250, 250, 250, 0.2);
  }

  .offcanvas-top {
    border-bottom: 1px solid rgba(250, 250, 250, 0.2);
  }

  .offcanvas-bottom {
    border-top: 1px solid rgba(250, 250, 250, 0.2);
  }

  .placeholder {
    background-color: currentColor;
    opacity: 0.5;
  }

  @keyframes placeholder-glow {
    50% {
      opacity: 0.2;
    }
  }
  .placeholder-wave {
    mask-image: linear-gradient(130deg, #fafafa 55%, rgba(255, 255, 255, 0.8) 75%, #fafafa 95%);
  }

  .link-primary {
    color: #375a7f;
  }
  .link-primary:hover, .link-primary:focus {
    color: #2c4866;
  }

  .link-secondary {
    color: #626262;
  }
  .link-secondary:hover, .link-secondary:focus {
    color: #4e4e4e;
  }

  .link-success {
    color: #00bc8c;
  }
  .link-success:hover, .link-success:focus {
    color: #33c9a3;
  }

  .link-info {
    color: #17a2b8;
  }
  .link-info:hover, .link-info:focus {
    color: #128293;
  }

  .link-warning {
    color: #f39c12;
  }
  .link-warning:hover, .link-warning:focus {
    color: #f5b041;
  }

  .link-danger {
    color: #e74c3c;
  }
  .link-danger:hover, .link-danger:focus {
    color: #b93d30;
  }

  .link-light {
    color: #9e9e9e;
  }
  .link-light:hover, .link-light:focus {
    color: #7e7e7e;
  }

  .link-dark {
    color: #3b3b3b;
  }
  .link-dark:hover, .link-dark:focus {
    color: #2f2f2f;
  }

  .vr {
    background-color: currentColor;
    opacity: 0.1;
  }

  .shadow {
    box-shadow: 0 0.5rem 1rem rgba(17, 17, 17, 0.15) !important;
  }

  .shadow-sm {
    box-shadow: 0 0.125rem 0.25rem rgba(17, 17, 17, 0.075) !important;
  }

  .shadow-lg {
    box-shadow: 0 1rem 3rem rgba(17, 17, 17, 0.175) !important;
  }

  .border {
    border: 1px solid #515151 !important;
  }

  .border-top {
    border-top: 1px solid #515151 !important;
  }

  .border-end {
    border-right: 1px solid #515151 !important;
  }

  .border-bottom {
    border-bottom: 1px solid #515151 !important;
  }

  .border-start {
    border-left: 1px solid #515151 !important;
  }

  .border-primary {
    border-color: #375a7f !important;
  }

  .border-secondary {
    border-color: #626262 !important;
  }

  .border-success {
    border-color: #00bc8c !important;
  }

  .border-info {
    border-color: #17a2b8 !important;
  }

  .border-warning {
    border-color: #f39c12 !important;
  }

  .border-danger {
    border-color: #e74c3c !important;
  }

  .border-light {
    border-color: #9e9e9e !important;
  }

  .border-dark {
    border-color: #3b3b3b !important;
  }

  .border-white {
    border-color: #fafafa !important;
  }

  .border-black {
    border-color: #111 !important;
  }

  .text-muted {
    --bs-text-opacity: 1;
    color: #9e9e9e !important;
  }

  .text-white-50 {
    --bs-text-opacity: 1;
    color: rgba(250, 250, 250, 0.5) !important;
  }

  .text-black-50 {
    --bs-text-opacity: 1;
    color: rgba(17, 17, 17, 0.5) !important;
  }

  .bg-black {
    --bs-bg-opacity: 1;
    background-color: #111 !important;
  }

  body::selection {
    color: #cfcfcf;
    background: rgba(23, 162, 184, 0.5);
  }
}
@media (prefers-color-scheme: light) {
  .d-light-inline {
    display: inline !important;
  }

  .d-light-inline-block {
    display: inline-block !important;
  }

  .d-light-block {
    display: block !important;
  }

  .d-light-grid {
    display: grid !important;
  }

  .d-light-table {
    display: table !important;
  }

  .d-light-table-row {
    display: table-row !important;
  }

  .d-light-table-cell {
    display: table-cell !important;
  }

  .d-light-flex {
    display: flex !important;
  }

  .d-light-inline-flex {
    display: inline-flex !important;
  }

  .d-light-none {
    display: none !important;
  }
}
@media (prefers-color-scheme: dark) {
  .d-dark-inline {
    display: inline !important;
  }

  .d-dark-inline-block {
    display: inline-block !important;
  }

  .d-dark-block {
    display: block !important;
  }

  .d-dark-grid {
    display: grid !important;
  }

  .d-dark-table {
    display: table !important;
  }

  .d-dark-table-row {
    display: table-row !important;
  }

  .d-dark-table-cell {
    display: table-cell !important;
  }

  .d-dark-flex {
    display: flex !important;
  }

  .d-dark-inline-flex {
    display: inline-flex !important;
  }

  .d-dark-none {
    display: none !important;
  }
}

/*# sourceMappingURL=bootstrap-dark-plugin.css.map */

/* FULLSCREEN LOADING MODAL */

.spinner {
    width: 100px;
    height: 100px;
    background-color: #EEEEEE;
    margin: 25px auto;
    -webkit-animation: sk-rotateplane 1.2s infinite ease-in-out;
    animation: sk-rotateplane 1.2s infinite ease-in-out;
}

.fullscreen-loading {
    color: #EEEEEE;
    background-color: transparent;
}

@-webkit-keyframes sk-rotateplane {
    0% {
        -webkit-transform: perspective(120px)
    }
    50% {
        -webkit-transform: perspective(120px) rotateY(180deg)
    }
    100% {
        -webkit-transform: perspective(120px) rotateY(180deg) rotateX(180deg)
    }
}

@keyframes sk-rotateplane {
    0% {
        transform: perspective(120px) rotateX(0deg) rotateY(0deg);
        -webkit-transform: perspective(120px) rotateX(0deg) rotateY(0deg)
    }
    50% {
        transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
        -webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg)
    }
    100% {
        transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
        -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
    }
}

/* COMPONENT: IMAGE OVERLAY FADE (THE NICE ONE) */

.image-overlay-container {
    position: relative;
    overflow: hidden;
    margin: auto;
}

.image {
    opacity: 1;
    display: block;
    width: 100%;
    height: auto;
    transition: .5s ease;
    backface-visibility: hidden;
}

.image-overlay-middle {
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
}

.image-overlay-topleft {
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    text-align: left;
}

.image-overlay-container:hover .image {
    opacity: 0.25;
    -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
    filter: grayscale(100%);
}

.image-overlay-container:hover .image-overlay-middle, .image-overlay-container:hover .image-overlay-topleft {
    opacity: 1;
}

.image-overlay-text {
    background-color: transparent;
    color: black;
}

/* Bootstrap 5 "tagin" plugin overrides (https://github.com/erwinheldy/tagin/) */
.tagin-tag {
    padding: 10px !important;
    background-color: var(--primary-color) !important;
}
.tagin-tag-remove {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='white' width='18px' height='18px'%3E%3Cpath d='M0 0h24v24H0z' fill='none'/%3E%3Cpath d='M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59z'/%3E%3C/svg%3E") !important;
}
.tagin-wrapper {
    vertical-align: center !important;
    padding: 6px !important;
}
// Interactive list items

.list-group-item-action {
  color: $list-group-action-color-alt;

  // Hover state
  &:hover,
  &:focus {
    color: $list-group-action-hover-color-alt;
    background-color: $list-group-hover-bg-alt;
  }

  &:active {
    color: $list-group-action-active-color-alt;
    background-color: $list-group-action-active-bg-alt;
  }
}

// Individual list items

.list-group-item {
  color: $list-group-color-alt;
  background-color: $list-group-bg-alt;
  border: $list-group-border-width solid $list-group-border-color-alt;

  &.disabled,
  &:disabled {
    color: $list-group-disabled-color-alt;
    background-color: $list-group-disabled-bg-alt;
  }

  // Include both here for `<a>`s and `<button>`s
  &.active {
    color: $list-group-active-color-alt;
    background-color: $list-group-active-bg-alt;
    border-color: $list-group-active-border-color-alt;
  }
}

// Flush list items

.list-group-flush {

  > .list-group-item {
    border-width: 0 0 $list-group-border-width;  // needed for nightshade variants

    &:last-child {
      border-bottom-width: 0;
    }
  }
}

// List group contextual variants

@each $state, $value in $theme-colors-alt {
  $list-group-variant-bg-alt: shift-color($value, $list-group-item-bg-scale-alt);
  $list-group-variant-color-alt: shift-color($value, $list-group-item-color-scale-alt);
  @if (contrast-ratio($list-group-variant-bg-alt, $list-group-variant-color-alt) < $min-contrast-ratio-alt) {
    $list-group-variant-color-alt: mix($value, color-contrast($list-group-variant-bg-alt, $color-contrast-dark-alt, $color-contrast-light-alt, $min-contrast-ratio-alt), abs($list-group-item-color-scale-alt));
  }

  @include list-group-item-variant($state, $list-group-variant-bg-alt, $list-group-variant-color-alt);
}

@import url('https://fonts.googleapis.com/css?family=Raleway:600,700');
@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600');

.dropdown-list .filter-textbox input {
  padding: 0;
  height: 40px;
  font-size: 14px;
}

.dropdown-list .filter-textbox {
  padding: 3px !important;
}

.multiselect-dropdown .dropdown-btn .selected-item-container .selected-item {
  border: none !important;
  margin-bottom: 1px !important;
}

.multiselect-item-checkbox input[type=checkbox] + div:before {
  border-color: #fff !important;
}

.dropdown-list .filter-textbox input {
  padding: 0 0 0 0px !important;
  background-color: #fff;
  color: black;
}

.dropdown-list {
  background: #42515E !important;
  box-shadow: none !important;
}

.multiselect-dropdown:hover {
background: #5C6D7A !important;
}

.dropdown-list li:hover {
background: #5C6D7A !important;
}

.filter-icon{
filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(125deg) brightness(104%) contrast(106%);
}

.table > :not(:first-child) {
border-top: none;
}

.container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
  max-width: 100%;
  padding-left: 9px;
}

.fixed-top{
  background: #42515e;
  top: 2px
}

:root {

  /*#FFFFFF*/
    --site-background-color: #24292e;
    background: #24292e;

    --free-color: #353b41;

    /*--primary-color: #1a95de;*/
    --primary-color-hover: #0d73c8;

    --info-color: #0dbeeb;
    --info-color-hover: #0db4e1;

    --success-color: #81c356;
    --success-color-hover: #70ad51;

    --danger-color: #dc3545;

    --subscribers-color: #81c356;
    --subscribers-color-hover: #70ad51;

    --exclusive-color: #fac10e;

    --warm-grey-color: rgba(123, 125, 110, 0.60);

    --collection-color: #fa7268; /* Living Coral */

    --tutorial-color: #b0b1a8;

    --strawberry-color: #d24a61;
}


html {
    font-size: 12px;
}

@media (min-width: 992px) {
    html {
        font-size: 14px;
    }
}

body {
    background-color: #24292e;
}

a {
    color: inherit;
    text-decoration: none !important;
    transition: all .1s ease-in-out;
}

a:not(.btn):hover {
    color: var(--primary-color) !important; /* blue colors for links too */
    text-decoration: none !important; /* no underline */
}

/* Fanxybox 3 */

@media (min-width: 1200px) {
    .fancybox-modals {
        width: 50%;
    }
}

.fbCustomBackgroundWhite .fancybox-bg {
    background : #fff;
}

.fbCustomBackgroundGrey .fancybox-bg {
    background : #ddd;
}

.fbCustomBackgroundWhiteMatte .fancybox-bg {
    background : #fff;
    opacity: 1;
}

/* End Fancybox 3 */

input::placeholder {
    color: darkgrey !important;
}

.invisible {
    opacity: 0;
}

hr.hr-cgmood {
    width: 40px;
    border: none;
    height: 2px;
    margin: auto;

    color: #888888; /* old IE */
    background-color: #888888; /* Modern Browsers */
}

hr.hr-cgmood-wider {
    width: 120px;
}

.cgmood-text-warning {
    color: #ff8214;
}

.cgmood-header {
    height: 100%;
    background-color: #0c1a1e;
    line-height: 140px;
    color: #f8f8f8;
    background-size: cover;
}

.cgmood-header img {
    vertical-align: middle;
    width: 350px;
}

@media only screen and (max-width: 500px) {
    .cgmood-header {
        line-height: 110px;
    }
}

.cgmood-page-header {
    height: 100%;
    min-height: 300px;
    background-color: #0c1a1e;
    background-size: cover;
}

.cgmood-title {
    font-family: 'Raleway', sans-serif;
    font-weight: 700;
    font-size: 2.6em;
    color: #333;
    margin: 0;
    padding: 0;
}

.cgmood-subtitle {
    font-family: 'Raleway', sans-serif;
    font-weight: 600;
    font-size: 2.2em;
    color: #333;
    margin: 0;
    padding: 0;
}

.cgmood-highlight-text {
    color: var(--collection-color);
}

.cgmood-menu-item {
    font-size: 1em;
    text-decoration: none;
    color: #333333;
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
}

.cgmood-navbar-item {
    font-size: 1.15em;
    text-decoration: none;
    color: #333333;
    font-weight: 600;
    font-family: 'Open Sans', sans-serif;
}

.text-over-image-container {
    position: relative;
    text-align: center;
}

.text-over-image-top-right {
    z-index: 4;
    position: absolute;
    top: 10px;
    right: 10px;
}

/* BADGES */
.badge-free {
    background-color: var(--free-color) !important;
    color: white;
}

.badge-credits {
    background-color: var(--info-color) !important;
    color: white;
}

.badge-discount {
    background-color: var(--primary-color) !important;
    color: white;
}

.badge-exclusive {
    background-color: var(--exclusive-color) !important;
    color: white;
}

.badge-subscribers {
    background-color: var(--subscribers-color) !important;
    color: white;
}

.badge-collection {
    background-color: var(--collection-color) !important;
    color: white;
}

.badge-tutorial {
    background-color: var(--tutorial-color) !important;
    color: white;
}

/* END BADGES */

/* TEXT COLORS */

.text-collection {
    color: var(--collection-color);
}

.text-lightgrey {
    color: #eee;
}

/* END TEXT COLORS */

.download-button {
    padding: 10px;
}

.shadow {
    -webkit-box-shadow: 0px 0px 15px 2px rgba(0,0,0,0.15);
    -moz-box-shadow: 0px 0px 15px 2px rgba(0,0,0,0.15);
    box-shadow: 0px 0px 15px 2px rgba(0,0,0,0.15);
}

.bg-white {
    background-color: rgb(255, 255, 255);
    background-color: rgba(255, 255, 255, 1.0);
    border-radius: 0px;
    -webkit-box-shadow: 0px 0px 30px 2px rgba(0,0,0,0.15);
    -moz-box-shadow: 0px 0px 30px 2px rgba(0,0,0,0.15);
    box-shadow: 0px 0px 30px 2px rgba(0,0,0,0.15);
}

.bg-white-small {
    background-color: rgb(255, 255, 255);
    background-color: rgba(255, 255, 255, 1.0);
    border-radius: 0px;
    -webkit-box-shadow: 0px 0px 15px 2px rgba(0,0,0,0.15);
    -moz-box-shadow: 0px 0px 15px 2px rgba(0,0,0,0.15);
    box-shadow: 0px 0px 15px 2px rgba(0,0,0,0.15);
}

.bg-dark {
    background-color: rgb(25, 25, 25);
    border-radius: 0px;
    -webkit-box-shadow: 0px 0px 30px 2px rgba(0,0,0,0.15);
    -moz-box-shadow: 0px 0px 30px 2px rgba(0,0,0,0.15);
    box-shadow: 0px 0px 30px 2px rgba(0,0,0,0.15);
}

.bg-darker {
    /* background: rgba(0, 0, 20, 0.25); */
    background: rgba(0, 0, 20, 0.25); /* Old browsers */
    background: -moz-linear-gradient(-45deg, rgba(0, 0, 20, 0.6) 0%, rgba(0, 0, 20, 0.25) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(-45deg, rgba(0, 0, 20, 0.6) 0%,rgba(0, 0, 20, 0.25) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(135deg, rgba(0, 0, 20, 0.6) 0%, rgba(0, 0, 20, 0.25) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */

}

.disabled {
    pointer-events: none;
    opacity: 0.4;
}

.fillet {
    border-radius: 12px !important;
}
.fillet-sm {
    border-radius: 8px !important;
}

.btn.fillet {
    border-radius: 8px !important;
}

.input-bg-white {
    background-color: rgb(255, 255, 255);
    background-color: rgba(255, 255, 255, 0.75);
    border-radius: 0;
}

.div-disabled {
    cursor: not-allowed;
    pointer-events: none;
    opacity: 0.4;
}

.smaller {
    font-size: 0.8rem;
}

.platform-icon {
    opacity: 0.5;
    -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
    filter: grayscale(100%);
}
.platform-icon:hover {
    opacity: 1.0;
    -webkit-filter: grayscale(0%); /* Safari 6.0 - 9.0 */
    filter: grayscale(0%);
}

.color {
    -webkit-filter: grayscale(0%); /* Safari 6.0 - 9.0 */
    filter: grayscale(0%);
    opacity: 1.0;
}

.color:hover {
    -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
    filter: grayscale(100%);
    opacity: 0.5;
}

/*
.btn-close {
    position: absolute;
    top: 5px;
    right: 5px;
    font-size: 2em;
}
 */

button {
    cursor: pointer;
}

/* CATEGORY ICONS */
.category:not(.selected) {
  opacity: 0.45;
  background: none;
}
.selected-category {
  opacity: 1 !important;
  background: #2199e8 !important;
}

.category:hover {
    opacity: 1;
    background: #6A6A6A;
}

/* LINKS */
.enlarge:hover {
    transform: scale(1.15);
    -webkit-transition:0.1s;
    -webkit-transform:scale(1.15);
}
.enlarge-smaller:hover {
    transform: scale(1.05);
    -webkit-transition:0.1s;
    -webkit-transform:scale(1.05);
}
.enlarge-tiny:hover {
    transform: scale(1.03);
    -webkit-transition:0.1s;
    -webkit-transform:scale(1.03);
}

/* User drop-down menu items */

.user-menu-item {
    color: white;
    font-size: 1em;
}

.user-menu-item > i {
    font-size: 1em;
}

.user-menu-item:hover {
    background-color: transparent !important;
    color: white !important;
}

/* User avatars and different sizes */

.user-avatar-navbar {
    width: 38px;
    height: 38px;
    object-fit: cover;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
}

.user-avatar {
    width: 48px;
    height: 48px;
    object-fit: cover;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
}

.user-avatar-notification {
    width: 32px;
    height: 32px;
    object-fit: cover;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
}

.user-avatar-60px {
    width: 60px;
    height: 60px;
    object-fit: cover;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
}

.user-avatar-medium {
    width: 120px;
    height: 120px;
    object-fit: cover;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
}
.user-avatar-big {
    width: 170px;
    height: 170px;
    object-fit: cover;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
}

/* Forms */
.no-border, .no-border:focus {
    border: solid 1px #e8e8e8;
    min-height: 36px;
    box-shadow: none; /* You may want to include this as bootstrap applies these styles too */
    background-color: transparent;
}

.no-border-bold, .no-border-bold:focus {
    border: solid 1px #e8e8e8;
    box-shadow: none; /* You may want to include this as bootstrap applies these styles too */
    background-color: transparent;
    font-weight: bolder;
}

.no-border:focus, .no-border-bold:focus {
    border-color: #999999 !important;
}
.no-border:hover, .no-border-bold:hover {
    border-color: #dbdbdb;
}

.big-borderless-input {
    border: none;
    border-bottom: 2px solid grey;
    padding: 4px;
    background-color: white;
    font-size: 1.8em;
    color: grey;
    text-align: center;
    opacity: 1;
    width: 100%;
}

.cgmood-label {
    text-decoration: none;
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    margin-bottom: 4px;
}

/* STRIKETHROUGH (for discounts etc) */
.strikethrough {
    position: relative;
    margin-left: 2px; margin-right: 2px;
}
.strikethrough:before {
    position: absolute;
    content: "";
    left: -3px;
    top: 50%;
    right: -3px;
    border-top: 3px solid;
    border-color: inherit;

    -webkit-transform:rotate(-15deg);
    -moz-transform:rotate(-15deg);
    -ms-transform:rotate(-15deg);
    -o-transform:rotate(-15deg);
    transform:rotate(-15deg);
}

/* Product component */

.product-element {
    transition: all .1s ease-in-out;
    padding: 10px;
}

.product-element:hover {
    transform: scale(1.025);
    -webkit-transition: 0.1s;
}

.product-element-favorite {
    margin: 5px;
    padding: 5px;
}

.product-title {
    font-family: 'Open Sans', sans-serif;
    font-size: 0.98em;
    font-weight: 600;
}

.col.info {
    color: dimgrey;
    font-size: 0.9em;
}

.platform-icon-16px {
    opacity: 0.25;
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
}

.platform-icon-16px:hover {
    opacity: 1.0;
    -webkit-filter: grayscale(0%);
    filter: grayscale(0%);
}

.platform-icons {
    position: relative;
    text-align: center;
}

.bottom-left {
    position: absolute;
    bottom: 5px;
    left: 5px;
}
.top-left {
    position: absolute;
    top: 5px;
    left: 5px;
}

/* End product component */

/* Rainbow animated text (using Splitting.js) */
.rainbow-text .char {
    color: hsl(calc(360deg * var(--char-percent)), 90%, 65%);
}

.rainbow-text.animated .char {
    animation: rainbow-colors 2s linear infinite;
    animation-delay: calc(-2s * var(--char-percent));
}

/* Unfortunately, browsers try to take the shortest distance between transition/animation properties, so a simple `0turn` to `1turn` doesn't get the proper effect. */
@keyframes rainbow-colors {
    0% { color: hsl(0turn, 90%, 65%); }
    25% { color: hsl(.25turn, 90%, 65%); }
    50% { color: hsl(.5turn, 90%, 65%); }
    75% { color: hsl(.75turn, 90%, 65%); }
    100% { color: hsl(1turn, 90%, 65%); }
}
/* End rainbow animated text */

button{
  background: #31373d !important;
  color: white !important;
  border: none !important;
}

button {
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  color: #e1e1e1;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem 0.1px;
  font-size: 1rem;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

button:hover{
  background: #276eaf !important;
  color: white !important;
  border: none !important;
}

.important-button{
  background: #2579c5 !important;
  color: white !important;
  border: none !important;
}

.important-button:hover{
  background: #2384dd !important;
  color: white !important;
  border: none !important;
}

.form-control-sm {
  padding: 0.25rem 0.1px 0.1px 0.1px;
  width: 16vw;
}

div + .form-control-sm {
  padding: 0.25rem 0.1px 0.1px 0.1px;
  width: auto;
  padding-left: 1px;
}

.btn-active-light-primary, .copy-btn{
  background: transparent !important;
}

.ngx-pagination a:hover, .ngx-pagination button:hover {
  background: #6a6a6a !important;
}

.ngx-slider {
  margin: 15px 0 15px !important;
  margin-right: 15px !important;
}

.ngx-slider .ngx-slider-pointer {
  width: 10px !important;
  height: 10px !important;
  top: -4px !important;
}

.ngx-slider .ngx-slider-pointer:after {
  content: '';
  width: 8px;
  height: 8px;
  position: absolute;
  top: 1px !important;
  left: 1px !important;
  border-radius: 4px;
  background: #fff;
}

.ngx-slider .ngx-slider-bar {
  height: 1px !important;
}

.text-muted{
  color: #e3e3e3 !important;
}

.table > :not(caption) > * > *{
  padding: 0.5rem 0.7rem;
}

app-table-image{
  height: 100%;
  /*display: block;*/
}

.btn-editing{
  background: #248a72 !important;
}

.sidebar2{
  background-color: #42515E;
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #b1b1b1;
  background-color: #222;
  background-clip: padding-box;
  border: 1px solid #515151;
  -webkit-appearance: none;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #b1b1b1;
  text-align: center;
  white-space: nowrap;
  background-color: #3b3b3b;
  border: 1px solid #515151;
  border-radius: 0.25rem;
}

.table {
  --bs-table-bg: transparent;
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: #e1e1e1;
  --bs-table-striped-bg: rgba(250, 250, 250, 0.05);
  --bs-table-active-color: #e1e1e1;
  --bs-table-active-bg: rgba(250, 250, 250, 0.1);
  --bs-table-hover-color: #e1e1e1;
  --bs-table-hover-bg: rgba(250, 250, 250, 0.075);
  width: 100%;
  margin-bottom: 1rem;
  color: #e1e1e1;
  vertical-align: top;
  border-color: #515151;
}

.form-check-input:checked {
  background-color: #375a7f;
  border-color: #375a7f;
}

.form-check-input {
  width: 1em;
  height: 1em;
  margin-top: 0.25em;
  vertical-align: top;
  background-color: #222;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid rgba(255, 255, 255, 0.25);
  -webkit-appearance: none;
  appearance: none;
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
}

.modal-content {
  /*width: 68vw;*/
  /*height: 85vh;*/

  position: fixed;
  top: 50%;
  left: 45%;
  transform: translate(-40%, -50%);
  width: 35%;
}

.modal-content{
  animation: none;
  animation-duration: 0s;
  --animate-repeat: 0;
  --animate-delay: 0;
  --animate-duration: 0s;
}

.modal-dialog{
  animation: none;
  animation-duration: 0s;
  --animate-repeat: 0;
  --animate-delay: 0;
  --animate-duration: 0s;
}

.myCustomModalClass{
  animation: none;
  animation-duration: 0s;
  --animate-repeat: 0;
  --animate-delay: 0;
  --animate-duration: 0s;
}

.modal-dialog-centered{
  animation: none;
  animation-duration: 0s;
  --animate-repeat: 0;
  --animate-delay: 0;
  --animate-duration: 0s;
}

.mt-3 {
  color: white;
}

h3 {
  color: white;
}

.autocomplete-container .input-container input{
  background-color: #25292E !important;
  color: #fff !important;
}

.dropdown-list .filter-textbox input {
  background-color: #25292E !important;
  color: #fff !important;
}

.multiselect-dropdown{
  background-color: #25292E;
  color: white;
}

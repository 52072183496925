@mixin table-variant-alt($state, $background) {
  .table-#{$state} {
    $color: color-contrast(opaque($body-bg-alt, $background));
    $hover-bg: mix($color, $background, percentage($table-hover-bg-factor-alt));
    $striped-bg: mix($color, $background, percentage($table-striped-bg-factor-alt));
    $active-bg: mix($color, $background, percentage($table-active-bg-factor-alt));
    $border-color: mix($color, $background, percentage($table-border-factor-alt));

    --#{$variable-prefix}table-color: #{$color};
    --#{$variable-prefix}table-bg: #{$background};
    --#{$variable-prefix}table-border-color: #{$border-color};
    --#{$variable-prefix}table-striped-bg: #{$striped-bg};
    --#{$variable-prefix}table-striped-color: #{color-contrast($striped-bg)};
    --#{$variable-prefix}table-active-bg: #{$active-bg};
    --#{$variable-prefix}table-active-color: #{color-contrast($active-bg)};
    --#{$variable-prefix}table-hover-bg: #{$hover-bg};
    --#{$variable-prefix}table-hover-color: #{color-contrast($hover-bg)};

    color: $color;  // color: var(--#{$variable-prefix}table-color);  // TODO: RE-REGRESS AFTER 5.3.1!!
    border-color: mix($color, $background, percentage($table-border-factor-alt));  // border-color: var(--#{$variable-prefix}table-border-color);  // TODO: RE-REGRESS AFTER 5.3.1!!
  }
}

/*!
 * Bootstrap v5.x.x (https://getbootstrap.com/)
 * Copyright 2011-2022 The Bootstrap Authors
 * Copyright 2011-2022 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 *
 * Bootstrap-Nightfall (https://vinorodrigues.github.io/bootstrap-dark-5/)
 * Copyright 2020-2022 Vino Rodrigues
 * This version is an extraction with only the dark elements, or deltas, of the
 * dark theme.  Used as a bootstrap plugin.
 */

// Configuration
@import "functions";
@import "dark/functions";
@import "variables";  // defaults are here
@import "variables-alt";  // dark color set is here
@import "mixins";
@import "dark/mixins";
@import "utilities";
@import "dark/utilities";
@import "dark/patch";  // missing from BS, unsupported patch/hack

:root { color-scheme: dark; }

// Layout & components
@import "dark/root";
@import "dark/reboot";
@import "dark/type";
@import "dark/images";
@import "dark/tables";
@import "dark/forms";
@import "dark/buttons";
@import "dark/dropdown";
@import "dark/button-group";
@import "dark/nav";
@import "dark/navbar";
@import "dark/card";
@import "dark/accordion";
@import "dark/breadcrumb";
@import "dark/pagination";
@import "dark/badge";
@import "dark/alert";
@import "dark/progress";
@import "dark/list-group";
@import "dark/close";
@import "dark/toasts";
@import "dark/modal";
@import "dark/tooltip";
@import "dark/popover";
@import "dark/carousel";
@import "dark/offcanvas";
@import "dark/placeholders";

// Helpers
@import "dark/helpers";

// Utilities
@import "dark/utilities/api";

// Unique to dark-mode
@import "dark/dark";

.popover {
  background-color: $popover-bg-alt;
  border: $popover-border-width solid $popover-border-color-alt;
  @include box-shadow($popover-box-shadow-alt);

  .popover-arrow {
    &::before,
    &::after {
      border-color: transparent;
      border-style: solid;
    }
  }
}

.bs-popover-top,
.bs-popover-auto[data-popper-placement^="top"] {  // see note below
  > .popover-arrow {
    &::before {
      border-top-color: $popover-arrow-outer-color-alt;
    }

    &::after {
      border-top-color: $popover-arrow-color-alt;
    }
  }
}

.bs-popover-end,
.bs-popover-auto[data-popper-placement^="right"] {  // see note below
  > .popover-arrow {
    &::before {
      border-right-color: $popover-arrow-outer-color-alt;
    }

    &::after {
      border-right-color: $popover-arrow-color-alt;
    }
  }
}

.bs-popover-bottom,
.bs-popover-auto[data-popper-placement^="bottom"] {  // see note below
  > .popover-arrow {
    &::before {
      border-bottom-color: $popover-arrow-outer-color-alt;
    }

    &::after {
      border-bottom-color: $popover-arrow-color-alt;
    }
  }

  .popover-header::before {
    border-bottom: $popover-border-width solid $popover-header-bg-alt;
  }
}

.bs-popover-start,
.bs-popover-auto[data-popper-placement^="left"] { // see note below
  > .popover-arrow {
    &::before {
      border-left-color: $popover-arrow-outer-color-alt;
    }

    &::after {
      border-left-color: $popover-arrow-color-alt;
    }
  }
}

// ***NOTE***: Do not use @extend as that would extend the base class and the dark class

// Offset the popover to account for the popover arrow
.popover-header {
  color: $popover-header-color-alt;
  background-color: $popover-header-bg-alt;
  border-bottom: $popover-border-width solid $popover-border-color-alt;
}

.popover-body {
  color: $popover-body-color-alt;
}
